// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-projects-project-detail-jsx": () => import("./../../../src/components/projects/ProjectDetail.jsx" /* webpackChunkName: "component---src-components-projects-project-detail-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-courses-frontend-jsx": () => import("./../../../src/pages/courses/frontend.jsx" /* webpackChunkName: "component---src-pages-courses-frontend-jsx" */),
  "component---src-pages-courses-frontend-sign-up-jsx": () => import("./../../../src/pages/courses/frontend/sign-up.jsx" /* webpackChunkName: "component---src-pages-courses-frontend-sign-up-jsx" */),
  "component---src-pages-courses-frontend-success-cash-jsx": () => import("./../../../src/pages/courses/frontend/success-cash.jsx" /* webpackChunkName: "component---src-pages-courses-frontend-success-cash-jsx" */),
  "component---src-pages-courses-frontend-success-jsx": () => import("./../../../src/pages/courses/frontend/success.jsx" /* webpackChunkName: "component---src-pages-courses-frontend-success-jsx" */),
  "component---src-pages-courses-git-jsx": () => import("./../../../src/pages/courses/git.jsx" /* webpackChunkName: "component---src-pages-courses-git-jsx" */),
  "component---src-pages-courses-index-jsx": () => import("./../../../src/pages/courses/index.jsx" /* webpackChunkName: "component---src-pages-courses-index-jsx" */),
  "component---src-pages-courses-javafx-jsx": () => import("./../../../src/pages/courses/javafx.jsx" /* webpackChunkName: "component---src-pages-courses-javafx-jsx" */),
  "component---src-pages-courses-react-jsx": () => import("./../../../src/pages/courses/react.jsx" /* webpackChunkName: "component---src-pages-courses-react-jsx" */),
  "component---src-pages-courses-react-sign-up-jsx": () => import("./../../../src/pages/courses/react/sign-up.jsx" /* webpackChunkName: "component---src-pages-courses-react-sign-up-jsx" */),
  "component---src-pages-courses-react-success-jsx": () => import("./../../../src/pages/courses/react/success.jsx" /* webpackChunkName: "component---src-pages-courses-react-success-jsx" */),
  "component---src-pages-courses-success-jsx": () => import("./../../../src/pages/courses/success.jsx" /* webpackChunkName: "component---src-pages-courses-success-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-methodology-jsx": () => import("./../../../src/pages/methodology.jsx" /* webpackChunkName: "component---src-pages-methodology-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-technologies-jsx": () => import("./../../../src/pages/technologies.jsx" /* webpackChunkName: "component---src-pages-technologies-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-work-in-progress-jsx": () => import("./../../../src/pages/work-in-progress.jsx" /* webpackChunkName: "component---src-pages-work-in-progress-jsx" */)
}

